/*eslint-disable */
import request from "../../../Utils/curl"
import Utility from "../../../Utils/utility"

let analyticsAdmissionDashboard = {
  /**
   * analytics_admission_state
   */
  async analytics_admission_state (context, whereFilter = null) {
    Utility.showLoader = true
    Utility.loadingMsg = "Please wait...."
    try {
      let postData = new FormData()
      if (whereFilter) {
        postData.append("filter", JSON.stringify(whereFilter))
      }

      return await request.curl(context, "analytics_admission_state", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at analytics_admission_state() and Exception:", err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },
  /**
   * analyticsAdmissionSchoolStatus
   */
  async analyticsAdmissionSchoolStatus (context, whereFilter = null) {
    Utility.showLoader = true
    Utility.loadingMsg = "Please wait...."
    try {
      let postData = new FormData()
      if (whereFilter) {
        postData.append("filter", JSON.stringify(whereFilter))
      }

      return await request.curl(context, "analytics_admission_state_school_wise", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at analyticsAdmissionSchoolStatus() and Exception:", err.message)
    }
    finally {
      Utility.showLoader = false
    }
  }
}

export {
  analyticsAdmissionDashboard
}
